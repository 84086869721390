import {
  SPACE_LIST_REQUEST,
  SPACE_LIST_SUCCESS,
  SPACE_LIST_FAIL,
  SPACE_DETAILS_REQUEST,
  SPACE_DETAILS_SUCCESS,
  SPACE_DETAILS_FAIL,
  SPACE_DELETE_REQUEST,
  SPACE_DELETE_SUCCESS,
  SPACE_DELETE_FAIL,
  SPACE_CREATE_REQUEST,
  SPACE_CREATE_FAIL,
  SPACE_CREATE_SUCCESS,
  SPACE_UPDATE_REQUEST,
  SPACE_UPDATE_FAIL,
  SPACE_UPDATE_SUCCESS,
  SPACE_CREATE_RESET,
  SPACE_UPDATE_RESET,
} from "../constants/spaceConstants";

export const spaceListReducer = (state = { spaces: [] }, action) => {
  switch (action.type) {
    case SPACE_LIST_REQUEST:
      return { loading: true, spaces: [] };
    case SPACE_LIST_SUCCESS:
      return { loading: false, spaces: action.payload };
    case SPACE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const spaceDetailsReducer = (
  state = {
    space: { images: [], category: [] },
  },
  action
) => {
  switch (action.type) {
    case SPACE_DETAILS_REQUEST:
      return { loading: true, ...state };
    case SPACE_DETAILS_SUCCESS:
      return { loading: false, space: action.payload };
    case SPACE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const spaceDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SPACE_DELETE_REQUEST:
      return { loading: true };
    case SPACE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SPACE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const spaceCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SPACE_CREATE_REQUEST:
      return { loading: true };
    case SPACE_CREATE_SUCCESS:
      return { loading: false, success: true, space: action.payload };
    case SPACE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SPACE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const spaceUpdateReducer = (state = { space: {} }, action) => {
  switch (action.type) {
    case SPACE_UPDATE_REQUEST:
      return { loading: true };
    case SPACE_UPDATE_SUCCESS:
      return { loading: false, success: true, space: action.payload };
    case SPACE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SPACE_UPDATE_RESET:
      return { space: [] };
    default:
      return state;
  }
};
