import {
  MASTER_DATA_FAIL,
  MASTER_DATA_REQUEST,
  MASTER_DATA_SUCCESS,
} from "../constants/masterDataConstants";

export const masterDataReducer = (state = { masterData: [] }, action) => {
  switch (action.type) {
    case MASTER_DATA_REQUEST:
      return { loading: true, masterData: [] };
    case MASTER_DATA_SUCCESS:
      return { loading: false, masterData: action.payload };
    case MASTER_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
