import { axiosInstance } from "../config";
import {
  SPACE_LIST_REQUEST,
  SPACE_LIST_SUCCESS,
  SPACE_LIST_FAIL,
  SPACE_DETAILS_REQUEST,
  SPACE_DETAILS_SUCCESS,
  SPACE_DETAILS_FAIL,
  SPACE_DELETE_REQUEST,
  SPACE_DELETE_SUCCESS,
  SPACE_DELETE_FAIL,
  SPACE_CREATE_REQUEST,
  SPACE_CREATE_FAIL,
  SPACE_CREATE_SUCCESS,
  SPACE_UPDATE_REQUEST,
  SPACE_UPDATE_FAIL,
  SPACE_UPDATE_SUCCESS,
} from "../constants/spaceConstants";

export const listSpaces = () => async (dispatch) => {
  try {
    dispatch({ type: SPACE_LIST_REQUEST });
    const { data } = await axiosInstance.get(`/api/spaces`);

    dispatch({ type: SPACE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SPACE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listSpaceDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: SPACE_DETAILS_REQUEST });

    const { data } = await axiosInstance.get(`/api/spaces/${id}`);

    dispatch({ type: SPACE_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SPACE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const DeleteSpace = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPACE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    console.log("delete Space", id);
    await axiosInstance.delete(`/api/spaces/${id}`, config);
    dispatch({
      type: SPACE_DELETE_SUCCESS,
    });
    console.log("delete Space success", id);
  } catch (error) {
    dispatch({
      type: SPACE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CreateSpace = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPACE_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axiosInstance.post(`/api/spaces/`, {}, config);
    console.log("data create space", JSON.stringify(data));
    dispatch({
      type: SPACE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SPACE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateSpace = (space) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPACE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/api/spaces/${space._id}`,
      space,
      config
    );
    dispatch({
      type: SPACE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SPACE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
