export const SPACE_LIST_REQUEST = "SPACE_LIST_REQUEST";
export const SPACE_LIST_SUCCESS = "SPACE_LIST_SUCCESS";
export const SPACE_LIST_FAIL = "SPACE_LIST_FAIL";

export const SPACE_LIST_NEW_REQUEST = "SPACE_LIST_NEW_REQUEST";
export const SPACE_LIST_NEW_SUCCESS = "SPACE_LIST_NEW_SUCCESS";
export const SPACE_LIST_NEW_FAIL = "SPACE_LIST_NEW_FAIL";

export const SPACE_DETAILS_REQUEST = "SPACE_DETAILS_REQUEST";
export const SPACE_DETAILS_SUCCESS = "SPACE_DETAILS_SUCCESS";
export const SPACE_DETAILS_FAIL = "SPACE_DETAILS_FAIL";

export const SPACE_DELETE_REQUEST = "SPACE_DELETE_REQUEST";
export const SPACE_DELETE_SUCCESS = "SPACE_DELETE_SUCCESS";
export const SPACE_DELETE_FAIL = "SPACE_DELETE_FAIL";

export const SPACE_CREATE_REQUEST = "SPACE_CREATE_REQUEST";
export const SPACE_CREATE_SUCCESS = "SPACE_CREATE_SUCCESS";
export const SPACE_CREATE_FAIL = "SPACE_CREATE_FAIL";
export const SPACE_CREATE_RESET = "SPACE_CREATE_RESET";

export const SPACE_UPDATE_REQUEST = "SPACE_UPDATE_REQUEST";
export const SPACE_UPDATE_SUCCESS = "SPACE_UPDATE_SUCCESS";
export const SPACE_UPDATE_FAIL = "SPACE_UPDATE_FAIL";
export const SPACE_UPDATE_RESET = "SPACE_UPDATE_RESET";
