/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { listMasterData } from "./actions/masterDataActions";
import { listSpaces } from "./actions/spaceActions";
import { listProducts } from "./actions/productActions";
import HashLoader from "react-spinners/HashLoader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
import emailjs from "@emailjs/browser";

const ProductPage = lazy(() => import("./pages/ProductPage/ProductPage"));
const LoginScreen = lazy(() => import("./pages/Login/LoginScreen"));
const Space = lazy(() => import("./pages/SpaceAdmin/Space"));
const Users = lazy(() => import("./pages/UsersList/Users"));
const NotFoundPage = lazy(() => import("./components/NotFoundPage"));
const EditUser = lazy(() => import("./pages/UserEdit/EditUser"));
const Products = lazy(() => import("./pages/Products/Products"));
const EditProduct = lazy(() => import("./pages/EditProduct/EditProduct"));
const EditSpace = lazy(() => import("./pages/EditSpace/EditSpace"));
const Room2D = lazy(() => import("./pages/Room2D/Room2D"));
const Room2DDemo = lazy(() => import("./pages/Room3D/Room2DDemo"));
const Room2DDraft = lazy(() => import("./pages/Room3D/Room2DDraft"));
const Fabric = lazy(() => import("./pages/Room3D/Fabric"));
const Three = lazy(() => import("./pages/Room3D/Three"));

<script
  type="text/javascript"
  src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js"
></script>;
<script type="text/javascript">
  (function(){emailjs.init("dojLbrrxobM7UXMa4")})();
</script>;

const App = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listMasterData());
    dispatch(listProducts());
    dispatch(listSpaces());
  }, []);

  const masterDataList = useSelector((state) => state.masterDataList);
  const masterData = masterDataList?.masterData?.data || [];

  const spaceList = useSelector((state) => state.spaceList);
  const { spaces } = spaceList;

  const productList = useSelector((state) => state.productList);
  const { products } = productList;

  return (
    <div className="main">
      <ChakraProvider>
        <Router>
          <>
            <Suspense
              fallback={
                <div className="loading">
                  <HashLoader color={"#1e1e2c"} size={40} />
                </div>
              }
            >
              <Switch>
                <Route
                  path="/"
                  exact
                  component={(props) => (
                    <Room2D
                      masterData={masterData}
                      products={products}
                      spaces={spaces}
                      {...props}
                    />
                  )}
                />
                {/* <Route
                  path="/home"
                  component={(props) => (
                    <Room2DDemo masterData={masterData} {...props} />
                  )}
                />
                <Route
                  path="/d"
                  component={(props) => (
                    <Room2DDraft masterData={masterData} {...props} />
                  )}
                />
                <Route
                  path="/f"
                  component={(props) => (
                    <Fabric masterData={masterData} {...props} />
                  )}
                />
                <Route
                  path="/t"
                  component={(props) => (
                    <Three masterData={masterData} {...props} />
                  )}
                /> */}
                <Route path="/product/:id" component={ProductPage} />
                <Route path="/admin/login" component={LoginScreen} />
                <Route path="/admin/space-list" component={Space} />
                <Route path="/admin/user-list" component={Users} />
                <Route path="/admin/product-list" component={Products} />
                <Route path="/admin/user/:id/edit" component={EditUser} />
                <Route
                  path="/admin/product/:id/edit"
                  component={(props) => (
                    <EditProduct masterData={masterData} {...props} />
                  )}
                />
                <Route
                  path="/admin/space/:id/edit"
                  component={(props) => (
                    <EditSpace masterData={masterData} {...props} />
                  )}
                />

                <Route path="*" component={NotFoundPage} />
              </Switch>
            </Suspense>
          </>
        </Router>
      </ChakraProvider>
    </div>
  );
};
export default App;
