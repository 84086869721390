import { axiosInstance } from "../config";
import {
  MASTER_DATA_FAIL,
  MASTER_DATA_REQUEST,
  MASTER_DATA_SUCCESS,
} from "../constants/masterDataConstants";

export const listMasterData = () => async (dispatch) => {
  try {
    dispatch({ type: MASTER_DATA_REQUEST });
    const { data } = await axiosInstance.get(`/api/masterData`);

    dispatch({ type: MASTER_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MASTER_DATA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
