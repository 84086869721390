import axios from "axios";

// export const BACKEND_URL = "http://localhost:5000";
// export const axiosInstance = axios.create({
//   baseURL: "https://hoachatnhatanh.com",
// });

// export const BACKEND_URL = "http://localhost:5000"; https://thietke.takaotile.vn
export const axiosInstance = axios.create({
  baseURL: " https://thietke.takaotile.vn",
});
