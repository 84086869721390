import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import HashLoader from "react-spinners/HashLoader";
ReactDOM.render(
  <Provider store={store}>
    <Suspense
      fallback={
        <div className="loading">
          <HashLoader color={"#1e1e2c"} size={40} />
        </div>
      }
    >
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
